import { Box, HStack, theme, Text } from '@chakra-ui/react'
import formatMoney from 'utils/formatMoney'

import { ICustomTooltipProps } from './BarChart.interface'

export const CustomTooltip = ({ payload }: ICustomTooltipProps) => {
  if (payload?.length) {
    const [key1, key2, key3] = payload

    return (
      <Box
        width={'max-content'}
        background={'white'}
        borderRadius={'10'}
        px={'2'}
        py={'2'}
        boxShadow="0px 3.45362px 34.5362px rgba(170, 170, 170, 0.25)"
      >
        <HStack alignItems={'flex-start'} gap={'2'}>
          <Text fontSize={'md'}>{key1.name}</Text>
          <Text fontSize={'lg'} color={theme.colors.green[500]}>
            {formatMoney(key1.value)}
          </Text>
        </HStack>
        <HStack alignItems={'flex-start'} gap={'2'}>
          <Text fontSize={'md'}>{key2.name}</Text>
          <Text fontSize={'lg'} color={theme.colors.green[200]}>
            {formatMoney(key2.value)}
          </Text>
        </HStack>
        <HStack alignItems={'flex-start'} gap={'2'}>
          <Text fontSize={'md'}>{key3.name}</Text>
          <Text fontSize={'lg'} color={'#8884d8'}>
            {key3.value}
          </Text>
        </HStack>
      </Box>
    )
  }

  return null
}
