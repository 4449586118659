import {
  CircularProgress,
  CircularProgressLabel,
  Divider,
  Flex,
  Heading,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { theme } from 'styles/theme'
import formatMoney from 'utils/formatMoney'

import { IRankColors, IRankingProps } from './Ranking.interface'

const rankColors: IRankColors = {
  1: theme.colors.service2[100],
  2: theme.colors.grey[400],
  3: theme.colors.service7[100],
  4: theme.colors.service5[100],
  5: theme.colors.service4[100],
}

const Ranking = ({ data, title }: IRankingProps) => {
  const sortedData = data.sort((data1, data2) => data1.ranking - data2.ranking)

  return (
    <Flex wrap={'wrap'} justifyContent={'space-around'} gap={'2rem'}>
      <VStack
        background={'white'}
        borderRadius={'2xl'}
        py={'3'}
        alignItems={'flex-start'}
        borderColor={'gray.200'}
        minH={'96'}
        pt={'5'}
        width={{ xs: 'full', xl: '600px' }}
      >
        <Heading as={'h2'} size={'md'} textAlign={'left'} py={'2'} px={'5'}>
          {title}
        </Heading>
        <Divider />
        <HStack
          justifyContent={'space-between'}
          width={'full'}
          px={'5'}
          py={'2'}
        >
          <Text fontSize={14} textAlign={'start'} width={'full'}>
            Ranking
          </Text>
          <Text fontSize={14} textAlign={'start'} width={'full'}>
            Grupo de cliente
          </Text>
          <Text fontSize={14} textAlign={'start'} width={'full'}>
            Valor em vendas
          </Text>
          <Text fontSize={14} textAlign={'start'} width={'full'}>
            Porcentagem
          </Text>
        </HStack>
        <Divider />
        {sortedData.map((rank, idx) => (
          <HStack
            key={rank.grupoclienteid}
            justifyContent={'space-between'}
            width={'full'}
            px={'5'}
          >
            <Text fontSize={14} textAlign={'start'} width={'full'}>
              <CircularProgress
                value={100}
                color={rankColors[idx + 1]}
                thickness="12px"
                size={'10'}
                fontWeight={'bold'}
              >
                <CircularProgressLabel fontSize={12}>
                  {rank.ranking}º
                </CircularProgressLabel>
              </CircularProgress>
            </Text>
            <Text
              fontSize={14}
              textAlign={'start'}
              width={'full'}
              color={'gray.400'}
            >
              {rank.grupoclientedescricao}
            </Text>
            <Text
              fontSize={14}
              textAlign={'start'}
              width={'full'}
              color={'gray.400'}
            >
              {formatMoney(rank.totalvenda, 2)}
            </Text>
            <Text
              fontSize={14}
              textAlign={'start'}
              width={'full'}
              color={'gray.400'}
            >
              {rank.rankingpercentual} %
            </Text>
          </HStack>
        ))}
      </VStack>
    </Flex>
  )
}

export default Ranking
