import { ReactNode } from 'react'
import CurrencyInput from 'react-currency-input'
import InputMask from 'react-input-mask'

import {
  FormControl,
  Input as ChackraInput,
  FormLabel,
  InputProps,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { useField } from 'formik'

interface IInputProps extends InputProps {
  name: string
  label?: string
  width?: string | number
  rightElement?: ReactNode
  variant?: 'switch' | 'input'
  mask?: string | Array<string | RegExp>
  chakraVariant?: string
  nothingLabel?: boolean
  displayFlex?: boolean
  isChecked?: boolean
  maskChar?: string
  currency?: boolean
  suffix?: string
  precision?: string
}

export const Input = ({
  name,
  label,
  width,
  rightElement,
  pr,
  nothingLabel,
  mask,
  chakraVariant = 'outline',
  displayFlex,
  currency,
  ...rest
}: IInputProps) => {
  const [field, meta] = useField(name)

  return (
    <FormControl
      display={displayFlex ? 'flex' : 'block'}
      width={width}
      isInvalid={meta.touched && !!meta.error}
    >
      {nothingLabel !== true ? (
        <FormLabel htmlFor={field.name} width={'full'}>
          {label}
        </FormLabel>
      ) : (
        ''
      )}
      <InputGroup>
        <ChackraInput
          precision={3}
          variant={chakraVariant}
          as={currency ? CurrencyInput : InputMask}
          onChangeEvent={field.onChange}
          mask={mask}
          decimalSeparator=","
          thousandSeparator="."
          {...rest}
          {...field}
          _focus={{
            borderColor: 'green.500',
          }}
          id={field.name}
          background="grey.200"
          height="48px"
        />
        {rightElement && (
          <InputRightElement height="100%" width={pr} pr={'2'}>
            {rightElement}
          </InputRightElement>
        )}
      </InputGroup>

      <FormErrorMessage position="absolute" maxH={'8'}>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  )
}
