import { useState } from 'react'

import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import axios from 'axios'
import Button from 'components/Button'
import { Input } from 'components/Input/Input'
import { Switch } from 'components/Switch'
import { Form, FormikProvider, FormikValues, useFormik } from 'formik'

import { apiWS } from '../../../services'
import RemoveNumberMask from '../../../utils/removeNumberMask'
import { IEditModalProps } from '../Products.interface'

const EditModal = ({ isOpen, onClose, product, pointId }: IEditModalProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const onSubmit = async (values: FormikValues) => {
    setIsLoading(true)

    const { ativo, precounitario } = values

    const newdata = {
      pontovendaid: pointId,
      ativo,
      precounitario:
        typeof precounitario === 'number'
          ? precounitario
          : RemoveNumberMask(precounitario),
      produtoid: product.produtoid,
    }

    try {
      await apiWS.post('WSSetPVProduto', newdata)

      toast({
        title: 'Dados alterados com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  const formik = useFormik({
    initialValues: {
      ativo: product.ativo,
      precounitario: product.precounitario,
    },
    onSubmit,
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent paddingBottom="2rem">
        <ModalHeader
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          borderRadius={'8px 8px 0px 0'}
          bg={'grey.300'}
        >
          <Heading fontWeight={'semibold'} size={'md'} lineHeight={'inherit'}>
            Editar Produto
          </Heading>

          <ModalCloseButton
            position={'relative'}
            top={'auto'}
            left={'auto'}
            _focus={{
              boxShadow: 'none',
              bg: '#fff',
            }}
            _hover={{
              bg: '#fff',
            }}
          />
        </ModalHeader>
        <Box p=" 2rem 25px 0 25px">
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Flex mb={'32px'}>
                <Switch name="ativo" label="Ativo ?" />
              </Flex>
              <Flex mb={'32px'}>
                <Input
                  currency
                  name="precounitario"
                  label="Preço"
                  prefix="R$ "
                />
              </Flex>
              <Flex justifyContent={'space-between'}>
                <Button
                  text="Cancelar"
                  size="sm"
                  bg={'grey.300'}
                  color={'gray'}
                  onClick={onClose}
                />

                <Button
                  text="Salvar"
                  type="submit"
                  size="sm"
                  isLoading={isLoading}
                />
              </Flex>
            </Form>
          </FormikProvider>
        </Box>
      </ModalContent>
    </Modal>
  )
}

export default EditModal
