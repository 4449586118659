import { useState } from 'react'

import {
  Flex,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import axios from 'axios'
import Button from 'components/Button'
import { Input } from 'components/Input/Input'
import { Loading } from 'components/Loading'
import { Switch } from 'components/Switch'
import Table from 'components/Table'
import { Form, FormikProvider, FormikValues, useFormik } from 'formik'

import { apiWS } from '../../../services'
import {
  IBenefits,
  IBenefitsForm,
  IUserAppFormProps,
} from '../Products.interface'

const headers = [
  { title: 'GRUPO DE CLIENTE', key: 'grupoclientedescricao' },
  { title: 'VALOR DESCONTO', key: 'valordesconto' },
  { title: 'QUANTIDADE DE PONTOS', key: 'qtpontos' },
  { title: 'VALOR CASHBACK', key: 'valorcashback' },
  { title: 'DESCONTO ATIVO?', key: 'isconcederdescontoenabled' },
  { title: 'PONTOS ATIVO?', key: 'isauferirpontosenabled' },
  { title: 'CASHBACK ATIVO?', key: 'isvalorcashbackenabled' },
]

const BenefitsModal = ({
  isOpen,
  onClose,
  benefits,
  pointId,
}: IUserAppFormProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()
  const onSubmit = async (values: FormikValues) => {
    setIsLoading(true)
    const { benefits } = values

    try {
      benefits.forEach(async (element: IBenefitsForm) => {
        await apiWS.post('WSSetPVBeneficio', {
          pontovendaid: pointId,
          ...element,
        })
      })

      toast({
        title: 'Dados alterados com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  const formik = useFormik({
    initialValues: {
      benefits: benefits.map((benefit) => {
        return {
          beneficioid: benefit.beneficioid,
          isconcederdescontoenabled: benefit.isconcederdescontoenabled,
          isauferirpontosenabled: benefit.isauferirpontosenabled,
          isvalorcashbackenabled: benefit.isvalorcashbackenabled,
        }
      }),
    },
    onSubmit,
  })
  const formatData = (data: IBenefits[]) => {
    return data.map((benefit: IBenefits, idx) => {
      const pontosMask = benefit.referenciapontos === 'Q' ? ' Litros' : ' R$'
      const descontoMask = benefit.referenciadesconto === 'P' ? ' %' : ' R$'
      const cashbackMask = benefit.referenciacashback === 'P' ? ' %' : ' R$'

      return {
        grupoclientedescricao: benefit.grupoclientedescricao,
        valordesconto: benefit.valordesconto + descontoMask,
        qtpontos: `${benefit.auferirpontos} pontos a cada ${
          benefit.proporcaopontos + pontosMask
        }`,
        valorcashback: benefit.auferircashback + cashbackMask,
        isconcederdescontoenabled: (
          <Flex justifyContent={'center'}>
            <Switch name={`benefits.[${idx}].isconcederdescontoenabled`} />
          </Flex>
        ),
        isauferirpontosenabled: (
          <Flex justifyContent={'center'}>
            <Switch name={`benefits.[${idx}].isauferirpontosenabled`} />
          </Flex>
        ),
        isvalorcashbackenabled: (
          <Flex justifyContent={'center'}>
            <Switch name={`benefits.[${idx}].isvalorcashbackenabled`} />
          </Flex>
        ),
      }
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <ModalContent paddingBottom="2rem" maxW="80vw" p={0}>
            <ModalHeader
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              borderRadius={'8px 8px 0px 0'}
              bg={'grey.300'}
            >
              <Heading
                fontWeight={'semibold'}
                size={'md'}
                lineHeight={'inherit'}
              >
                Benefícios
              </Heading>

              <ModalCloseButton
                position={'relative'}
                top={'auto'}
                left={'auto'}
                _focus={{
                  boxShadow: 'none',
                  bg: '#fff',
                }}
                _hover={{
                  bg: '#fff',
                }}
              />
            </ModalHeader>
            <Flex
              p=" 0 25px 2rem 25px"
              justifyContent={'center'}
              direction={'column'}
            >
              <Table headers={headers} data={formatData(benefits)} />
              <Flex w={'100%'} justifyContent="flex-end" mt={'1rem'}>
                <Button
                  text="Salvar"
                  type="submit"
                  size="sm"
                  isLoading={isLoading}
                />
              </Flex>
            </Flex>
          </ModalContent>
        </Form>
      </FormikProvider>
    </Modal>
  )
}

export default BenefitsModal
